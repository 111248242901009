<template>
  <edit-item-component endpoint="problems" type="problem"/>
</template>

<script>
export default {
  name: "Edit"
}
</script>

<style scoped>

</style>